import { Formik } from 'formik';
import React from 'react';
import { useOfferTypeMutator } from '../../../store/hooks/firestore';
import { useSaveNotify } from '../../../store/hooks/useSaveNotify';
import { imageUpload } from '../../ImageForm';
import { Content } from './Content';

const IMAGE_HEIGHT = 180;

export const Layout = ({ offerType }) => {
  const { saved, sending } = useSaveNotify();
  const { update } = useOfferTypeMutator();
  const onSubmit = async (values, props) => {
    const payload = {};
    ['shop_name', 'desc', 'layout', 'is_small_menu_image', 'itemDescFont'].forEach((k) => {
      if (k === 'desc' || k === 'shop_name' || k === 'is_small_menu_image') payload[k] = values[k];
      if (values[k]) payload[k] = values[k];
    });

    const { logo: editedImage, tempImage, isImageEdited } = values;
    const initialLogoUrl = offerType.logo;
    const needUpload =
      (editedImage && editedImage.startsWith('data:image/')) || (tempImage && tempImage.startsWith('data:image/'));
    if (needUpload) {
      sending();
      const image = isImageEdited ? editedImage : tempImage || editedImage;
      payload.logo = await imageUpload({ image, imageHeight: IMAGE_HEIGHT });
    } else if (editedImage !== undefined) {
      payload.logo = editedImage;
    }
    await update({ id: offerType.id, payload, updateDate: new Date() });
    if (initialLogoUrl && initialLogoUrl !== editedImage) {
      sending();
    }
    saved();
    props.setSubmitting(false);
  };

  const validateDescription = (values) => {
    const { desc } = values;
    if (!desc) {
      return;
    }
    const rowByText = desc.split(/\r?\n/g);
    if (rowByText.length > 3) {
      return { desc: `3行以内にしてください。現在 ${rowByText.length} 行です。` };
    }
  };
  return (
    <Formik validate={validateDescription} initialValues={offerType} onSubmit={onSubmit} enableReinitialize={true}>
      {Content}
    </Formik>
  );
};
