import { firebaseAuth } from '../../firebase';
import React, { useEffect, useState } from 'react';
import { useFunctionsDomain } from '../../store/hooks';
import { QRCoder } from './QRCoder';
import './styles.scss';
import { TableGrid } from './TableGrid';
import { FormHelperText, Input } from '@mui/material';
import FixedQRCodeList from '../FixedQRCodeList';
import { CustomButton } from '../../components/Button';
import { useAccount } from '../../store/hooks';

export const Screen = (props) => {
  const [guid, setGuid] = useState(null);
  const [tableNo, setTableNo] = useState(null);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [reviewAppUrl, setReviewAppUrl] = useState(null);
  const [error, setError] = useState(null);
  const [openFixedQR, setOpenFixedQR] = useState(false);
  const accountData = useAccount();
  const functionsDomain = useFunctionsDomain();

  const selectTable = (guid, tableNo) => {
    setGuid(guid);
    setTableNo(tableNo);
  };

  const fetchUser = React.useCallback(
    async (u) => {
      setAccount(u);
      const idToken = await firebaseAuth().currentUser.getIdToken();
      const options = { headers: { Authorization: idToken } };
      const response = await fetch(`${functionsDomain}/apiUbiregiAccountInfo`, options);
      const data = await response.json();
      setUser(data.account);
    },
    [functionsDomain],
  );
  useEffect(() => {
    document.title = 'テーブル一覧 - ユビレジQRオーダー&決済管理画面';
  }, []);

  useEffect(() => {
    firebaseAuth().onAuthStateChanged((u) => {
      if (u) fetchUser(u);
    });
  }, [fetchUser]);

  if (!user) return null;
  return (
    <div className='tables-page'>
      {/* TODO: 固定QR機能をアカウントの設定で制限をするために判定を追加。固定QRの正式リリース後に削除 */}
      {accountData && accountData?.settings['mobile-order']?.beta_fixed_qr_enabled && (
        <CustomButton
          style={{ width: '200px' }}
          onClick={() => setOpenFixedQR(!openFixedQR)}
          variant='contained'
          color={openFixedQR ? 'secondary' : 'primary'}
        >
          {openFixedQR ? '通常QRコードを表示' : '固定QRコードを表示'}
        </CustomButton>
      )}
      {!openFixedQR ? (
        <>
          <Input
            error={!!error}
            onChange={(e) => {
              try {
                new URL(e.target.value);
                setReviewAppUrl(e.target.value);
                setError(null);
              } catch (error) {
                setReviewAppUrl(null);
                setError(error);
              }
            }}
            placeholder='Please Enter Review App URL (e.g. https://qro-review-xxxx.ubiregi.jp/)'
            style={{ width: 600 }}
          />
          {error && (
            <FormHelperText error={!!error}>入力された文字列がurlの形式(https://...)ではありません</FormHelperText>
          )}
          <TableGrid user={user} selectTable={selectTable} account={account} />
          <QRCoder reviewAppUrl={reviewAppUrl} guid={guid} tableNo={tableNo} user={user} />
        </>
      ) : (
        <FixedQRCodeList user={user} />
      )}
    </div>
  );
};
