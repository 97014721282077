import { Box, Button, Checkbox, Typography, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useMenuListMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';

export function SearchByNumber({ menu, offerTypes }) {
  const theme = useTheme();
  const { update } = useMenuListMutator();
  const [enabledSearchByNumber, setEnabledSearchByNumber] = useState(!!menu.enabledSearchByNumber);
  const { sending, saved, loading } = useSaveNotify();

  const save = useCallback(async () => {
    sending();
    await update({ id: menu.id, payload: { enabledSearchByNumber }, updateSnapshot: offerTypes });
    saved();
  }, [menu, update, enabledSearchByNumber, sending, saved, offerTypes]);
  return (
    <>
      <Typography style={{ marginBottom: 16 }}>
        番号検索専用の設定です。
        <br />
        この設定を有効にすると、検索画面から番号入力するだけで商品を表示させることができます。
      </Typography>
      <Typography>
        ※ 事前に商品名の先頭に商品番号(半角数字3桁)を設定しておく必要があります。（例）100 生ビール
      </Typography>

      <Box border={1} borderColor='grey.300' padding={2} marginTop={2}>
        <Typography>番号検索を有効にすると…</Typography>
        <ul>
          <li>ページの内容は画面上で一覧表示されません。商品は検索結果でのみ表示されます。</li>
          <li>番号検索が有効になったメニューをメニューブックに設定するだけで番号検索ができるようになります。</li>
        </ul>
      </Box>
      <Box bgcolor={theme.palette.warning.light} padding={1} marginTop={2}>
        <Typography>
          番号検索が有効になったメニューが含まれるメニューブックは、キーワードでの検索ができなくなりますのでご注意ください。
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography>
          <Checkbox
            checked={enabledSearchByNumber}
            onChange={(e) => {
              setEnabledSearchByNumber(e.target.checked);
            }}
          />
          番号検索を有効にする
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Button disabled={loading} onClick={save} size='medium' type='submit' variant='contained' color='secondary'>
          保存
        </Button>
      </Box>
    </>
  );
}
