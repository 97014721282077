import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useClientHostName } from '../../store/hooks';

export const QRCoder = (props) => {
  const [sUrl, setsUrl] = useState(null);
  const [devUrl, setDevUrl] = useState(null);
  const { guid, user, reviewAppUrl } = props;
  const mo_domain = useClientHostName();

  const getUrl = React.useCallback(async () => {
    const { guid } = props;
    const urlOfHerokuEnv = `https://${mo_domain}/?guid=${guid}&account=${user.login}&id=${user.id}`;
    setDevUrl(`http://localhost:3000/?guid=${guid}&account=${user.login}&id=${user.id}`);

    const res = await fetch('https://s.ubiregi.com/post', {
      method: 'POST',
      body: JSON.stringify({ url: urlOfHerokuEnv }),
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await res.json();
    setsUrl(data.url);
  }, [props, user, mo_domain]);

  useEffect(() => {
    if (guid && user) {
      getUrl();
    }
  }, [guid, user, getUrl]);

  if (!guid) return null;
  return (
    <Paper className='list stylizedList codeList' elevation={3}>
      <div>{props.tableNo}</div>
      <div>
        {reviewAppUrl && (
          <div>
            <a
              href={`${reviewAppUrl}/?guid=${guid}&account=${user.login}&id=${user.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Review App Link
            </a>
          </div>
        )}
      </div>
      <div>
        <a href={devUrl} target='_blank' rel='noopener noreferrer'>
          開発用 Link
        </a>
      </div>
      <div>
        <a href={sUrl} target='_blank' rel='noopener noreferrer'>
          デモ用 Link
        </a>
      </div>
      {sUrl ? <QRCode value={sUrl} /> : null}
    </Paper>
  );
};
